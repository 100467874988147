<template>
    <div class="basic-info">
        <BasicComponent :details="details" v-bind="$attrs"/>
        <div class="row">
            <div>
                <span class="label">{{ $t('购车人') }}：</span>
                <span class="value">{{ details.carRegistration.ownerName || '--' }}</span>
            </div>
            <div>
                <span class="label">{{ $t('联系方式') }}：</span>
                <span class="value">{{ details.carRegistration.mobile || '--' }}</span>
            </div>
        </div>
        <div class="row">
            <div>
                <span class="label">{{ $t('下单人') }}：</span>
                <span class="value">{{ details.customerName || '--' }}</span>
            </div>
            <div>
                <span class="label">{{ $t('联系方式') }}：</span>
                <span class="value">{{ details.customerMobile || '--' }}</span>
            </div>
        </div>
        <div class="row border-bottom">
            <div>
              <span class="label">{{ $t('销售顾问') }}：</span>
              <span class="value">{{ details.salesUserName || '--'  }}</span>
            </div>
            <div>
              <span class="label">{{ $t('联系方式') }}：</span>
              <span class="value">{{ salesPhone || '--' }}</span>
            </div>
        </div>
        <div class="row sales">
          <div>
            <span class="label">{{ $t('上牌城市') }}：</span>
            <span class="value">{{ details.carRegistration.cityName || '--' }}</span>
          </div>
          <div>
            <span class="label">{{ $t('充电桩') }}：</span>
            <span class="value">{{ details.buyChargingStatus === 0 ? '未购买' : '已购买' || '--' }}</span>
          </div>
        </div>
        <div class="row sales">
          <div>
            <span class="label">{{ $t('服务包') }}：</span>
            <span class="value">{{ '--' }}</span>
          </div>
        </div>
        <div v-if="details.refund" class="row sales" style="color: #E4002C;" @click="moreOperate">
          <div>
            <span class="label">{{ $t('退单申请：') }}</span>
            <span class="value">{{ headerTips }}</span>
          </div>
        </div>
    </div>
</template>
<script>
import baseDataService from '@/services/baseDataServices.js'
import BasicComponent from './basic-component.vue'
import { mapGetters } from 'vuex'
export default {
  components: { BasicComponent },
  props: {
    details:{
      type: Object,
      default:()=>({})
    }
  },
  data(){
    return {
      salesPhone: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    isRisk(){
      return (this.details?.orderLabel || []).includes('4009001')
    },
    headerTips() {
      const arr = this.dictHash[3001]
      const { orderUpdateStatus, refund={} } = this.details
      const { refuseReason='', applyReason, applyStatus } = refund
      const statusName = arr.find(({ code }) => { return code === orderUpdateStatus })?.name
      // 有驳回原因 或者撤回原因需要展示
      const showReason = (applyStatus === '3001002' && applyReason) || (['3001025','3001026','3001027','3001028'].includes(applyStatus) && refuseReason)
      return showReason ? `${statusName} | ${showReason} >` : `${statusName} >`
    }
  },
  watch:{
    details:{
      async handler(val){
        const params = {
          dealerId: val.salesDealerId,
          roleLogo: '1014002'
        }
        const list = await baseDataService.getStaffList(params)
        const target = list.find(e=>e.id === val.salesUserId)
        if (target) this.salesPhone = target.phone
      },
      immediate: true,
      deep: true
    },
    
  },
  methods:{
    moreOperate() {
      const { id } = this.details
      const query = {
        id,
      }
      this.$router.push({
        path:'/refundOrder',
        query
      })
    },
  }
}
</script>
<style lang="less" scoped>
.basic-info{
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    .row{
        display: flex;
        justify-content: space-between;
        color: gray;
        & > div{
          flex: 1;
        }
        .label,.value{
            height: 22px;
            line-height: 22px;
            word-break: break-all;
        }
    }
    .border-bottom{
        border-bottom: 1px solid rgba(13, 23, 26, 0.05);
        padding-bottom: 12px;
    }
    .sales{
        
    }
}
</style>