<template>
    <div class="remote-deliver" :style="{'padding-bottom': isEdit || isApprove ?'96px':'12px'}">
        <DeliverBasicInfo :details="deliverDetail" :showDeliveryOffsiteStatus="true"/>
        <div class="middle deliverItem">
            <div class="common-t">
                <div class="common-t-l">{{$t('送车上门信息')}}</div>
            </div>
            <van-form ref="remote" input-align="right" error-message-align="right" class="deliverInfo">
                <van-field
                    :label="$t('交付方式')"
                    :placeholder="$t('请选择')"
                    :value="methodMap[formData.deliveryMethod]"
                    :right-icon="isEdit?'arrow':''"
                    :disabled="!isEdit"
                    readonly
                    input-align="right"
                    required
                    :rules="[{required:true}]"
                    @click="selectField('deliveryMethod', 'deliveryMethodName', 4007, false)">
                </van-field>
                <AddressSystem ref="addressSystem"
                    :label="$t('交付地点')"
                    :keys="addressKeys"
                    :form-data="addressObj"
                    :placeholder="$t('请选择')"
                    :inputAlign="'right'"
                    :isRequired="true"
                    :isDisabled="!isEdit"
                    :rules="[{required:true}]"
                    @change="onAddressChange">
                    </AddressSystem>
                <van-field v-model="formData.customerAddr.addr"
                    :label="$t('详细地址')"
                    required
                    :placeholder="$t('请输入')"
                    :disabled="!isEdit"
                    maxlength="50"
                    :rules="[{required:true}]"/>
                <div class="common-title">{{ $t('距离里程') }}</div>
                <div v-if="['4007002','4007003'].includes(formData.deliveryMethod)&&isEdit" style="color: rgba(13, 23, 26, 0.45);">
                  {{ formData.deliveryMethod==='4007002'?$t('请从客户所在地政府所在地导航到交付门店所在位置'):$t('请导航到客户详细地址') }}
                </div>
                <van-field v-model="formData.estimateDistance"
                  required
                  type="number" 
                  :label="$t('里程数（公里）')"
                  :placeholder="$t('请输入')"
                  :rules="[validate.checkInt]"
                  :disabled="!isEdit"
                  @blur="onBlur"/>
                <div v-if="isOverDistance" class="km-tips">已大于{{ costConfig.distanceConfig }}公里</div>
                <van-field v-model="formData.estimateCost"  
                  :label="$t('预估费用（元）')"
                  :disabled="!isEdit"
                  :rules="[{required:true,message: $t('请联系管理员获取预估费用')}]"
                  readonly/>
                <van-field required class="upload-img" :disabled="!isEdit" :rules="[{required:true,message: $t('请上传附件')}]">
                    <template #label>
                        <div class="label-tips">
                        <div>{{ $t('上传导航截图')}}</div>
                        </div>
                    </template>
                    <template #input>
                        <div>
                            <van-uploader
                                class="upload"
                                accept="image/*"
                                :preview-options="{closeable: true}"
                                :before-read="beforeRead"
                                :deletable="isEdit"
                                :after-read="(file) => afterRead(file)"
                                :file-list="fileList"
                                :before-delete="(file) => beforeDelete(file)"
                                :max-count="1"
                                multiple/>
                        </div>
                    </template>
                </van-field>
            </van-form>
        </div>
        <div v-if="['4014020', '4014025', '4014030'].includes(status)" class="approve-info deliverItem">
          <div> 
            <span>{{ operTypeMap[audit.operType] }}</span>
            <span class="ml4">{{ $t('驳回') }}</span>
            <span class="ml4">{{ audit.createTime }}</span>
          </div>
          <div>{{ $t('驳回原因') }}：</div>
          <div>{{ audit.rejectReason }}</div>
        </div>
       
        <div v-if="isEdit" class="common-footer">
            <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
            <van-button class="btn submit" @click="onSubmit">{{['4014020', '4014025', '4014030'].includes(status)?$t('重新发起申请'):$t('提交申请')}}</van-button>
        </div>
        <div v-if="isApprove" class="common-footer">
            <van-button class="btn back" @click="approve(2)">{{$t('驳回')}}</van-button>
            <van-button class="btn submit" @click="approve(1)">{{$t('通过')}}</van-button>
        </div>
        <!-- 审核弹窗 -->
        <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
            show-cancel-button 
            :cancel-button-text="$t('取消')" 
            :confirm-button-text="$t('驳回')"
            :close-on-click-overlay="true"
            :before-close="onReview">
            <van-field
              v-model="reviewRemark"
              rows="2"
              autosize
              label=""
              type="textarea"
              maxlength="70"
              :placeholder="$t('请输入驳回原因')"
              show-word-limit
            />
          </van-dialog>
    </div>
</template>
<script>
import DeliverBasicInfo from './component/deliver-basic-info.vue'
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
import commonSelector from '@/components/common-selector'
import AddressSystem from '@/components/addressSystem.vue'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import { validate } from '@/utils/validate'

export default {
  components:{ DeliverBasicInfo,AddressSystem },
  data(){
    return {
      validate,
      operTypeMap : {
        0: this.$t('创建'),
        1: this.$t('店长'),
        2: this.$t('区域'),
        3: this.$t('总部')
      },
      id: '',
      isEdit: true,
      isApprove: false,
      status: '',
      deliverDetail:{},
      formData: {
        deliveryMethod: '4007003',
        deliveryMethodName: '',
        customerAddr: {
          addr: ''
        },
        customerNaviUrl: '',
        estimateDistance: '',
        estimateCost: ''
      },
      addressObj:{},
      addressKeys:{
        cityCode:'cityCode',
        city:'cityName',
        provinceCode:'provinceCode',
        province:'provinceName',
        countyCode:'areaCode',
        county:'areaName',
      },
      fileList: [],
      isShowReview: false,
      reviewRemark: '',
      audit:{},
      costConfig: {
        // sendToCost 每公里花费
        // distanceConfig 里程设置
        // comToCost: {
        //   greaterConfig
        //   lessConfig
        // }
      },
      isOverDistance: false
    }
  },
  computed:{
    ...mapGetters(['dictHash','userInfo']),
    methodMap(){
      const map = {}
      this.dictHash[4007].filter(e=>e.code!=='4007001').forEach(e=>{
        map[e.code] = e.name
      })
      return map
    }
  },
  mounted(){
    const { id } = this.$route.query
    this.id = id
    // 查询交车单详情
    this.getDetail()
    
    // 异地交付费用配置
    this.getCostConfig()
  },
  methods: {
    async getCostConfig(){
      const res = await deliveryServices.getDeliveryConfig()
      this.$set(this,'costConfig',res)
    },
    onBlur(){
      if (!Object.keys(this.costConfig).length){
        this.$toast('获取异地交付费用配置失败，请稍后再试')
        return 
      }
      this.isOverDistance = this.formData.estimateDistance > this.costConfig.distanceConfig
      if (this.formData.deliveryMethod==='4007003'){
        // 送车上门  里程数 X 每公里费用
        this.formData.estimateCost = this.formData.estimateDistance * this.costConfig.sendToCost
        return
      }
      // 异地客户到店
      if (this.isOverDistance){
        this.formData.estimateCost = this.costConfig.comToCost.greaterConfig
      } else {
        this.formData.estimateCost = this.costConfig.comToCost.lessConfig
      }
    },
    // 获取交车详情
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.id })
      loading.hideLoading()
      this.deliverDetail = res || {}
      this.requestDetails()
    },
    // 申请详情
    async requestDetails(){
      const { customerNaviUrl,customerAddr,estimateDistance,estimateCost }=this.deliverDetail?.extend || {}
      const { deliveryMethod,deliveryOffsiteStatus: status }=this.deliverDetail
      if (estimateDistance) this.formData.estimateDistance = estimateDistance
      if (estimateCost) this.formData.estimateCost = estimateCost
      this.status = status || ''
      // 未申请&驳回  则可填写
      this.isEdit = ['4014020', '4014025', '4014030'].includes(status) || ['0','4007001'].includes(deliveryMethod)
      if (['4014020', '4014025', '4014030'].includes(status)){
        this.getHistory()
      }
      // 交付店长&待审核
      this.isApprove = '4014001' === status && this.userInfo.roleLogos.includes('1014007')

      if (['0','4007001'].includes(deliveryMethod)) return
      // 回显详情
      this.formData.deliveryMethod = deliveryMethod
      this.formData.customerAddr = customerAddr
      this.$set(this,'addressObj',customerAddr)
      // 回显图片
      this.fileList = customerNaviUrl.split(',').map(url => {
        return {
          url: url,
          cosUrl: url,
          status: 'done',
          message: '',
          uuid: uuidv4()
        }
      })
    },
    async getHistory(){
      const res = await deliveryServices.getReviewHistory(this.id)
      this.$set(this,'audit',res[res.length-1])
    },
    async selectField(field, name, dictType, multiple = false) {
      if (!this.isEdit) return
      // 不能直接用4007字典  因为要过滤掉到店交付的，所以走dictType: 'invalidReason',
      const _obj = {
        field,
        dictType: 'invalidReason',
        multiple,
        reasonList: this.dictHash[4007].filter(e=> !['4007001','4007002'].includes(e.code)).map(e=>({ id:e.code,reason:e.name })),
        lastSelect: this.formData[field]
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        this.formData[name] = res.dictName
      })
    },
    // 图片校验
    beforeRead(file) {
      const limitSize = 10 // 单位: mb
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (file.size > limitSize*1024*1024){
        this.$toast.fail(`${this.$t('文件不能超过')}${limitSize}Mb`)
        return false
      }
      
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = '上传中...'
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    onAddressChange(val){
      this.$set(this,'addressObj',val)
      Object.assign(this.formData.customerAddr,val)
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      loading.showLoading()
      console.log(this.formData)
      console.log(this.fileList)
      const p1 = this.$refs.addressSystem.$refs.form.validate()
      const p2 = this.$refs.remote.validate()
      Promise.all([p1,p2]).then(async()=>{
        if (this.formData.estimateCost===''){
          this.$toast(this.$t('请联系管理员获取预估费用'))
          return 
        }
        const params = {
          deliveryMethod: 1,
          ...this.formData,
          customerNaviUrl: this.fileList.length&&this.fileList[0].cosUrl,
          orderId: this.id,
          configDistance: this.costConfig.distanceConfig
        }
        const res = await deliveryServices.remoteDeliverRequest(params)
        loading.hideLoading()
        this.$toast.success(this.$t('提交成功'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      }).catch(error=>{
        loading.hideLoading()
      })
      
    },
    // type 1通过 2驳回
    approve(type){
      if (type===2){
        this.isShowReview = true
        return 
      }
      this.approveRequest(type)
    },
    // 审批请求
    approveRequest(type,reason){
      const params = {
        orderIds: [this.id],
        result: type,
        rejectReason: type=== 2 ? reason : '',
        type: 1
      }
      deliveryServices.remoteDeliverAudit(params).then(res => {
        this.$toast.success(this.$t('提交成功'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      })
    },
    // 审核驳回 1：取消、2：驳回
    async onReview(action, done){
      if (['overlay','cancel'].includes(action))done()

      if (action==='confirm'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm'].indexOf(action)>-1){
        await this.approveRequest(2,this.reviewRemark)
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.remote-deliver{
    padding: 12px 16px;
    font-size: 14px;
    .deliverItem {
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 8px 8px;
        width: 100%;
        box-sizing: border-box;
        margin-top: 16px;
        .upload-img{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            /deep/.van-field__label{
                max-width: 100%;
            }
            .placeholder-img{
                display: flex;
                flex-direction: column;
                color: #646566;
            }
        }
    }

    .middle {
        padding: 0 12px 12px;

        /deep/ .van-cell {
            padding: 10px 0;

            &::after {
                width: 100%;
                left: 0;
            }
        }
    }
    .approve-info{
      padding: 12px;
      font-size: 14px;
      color: #646566;
      line-height: 22px;
    }
    .ml4{
      margin-left: 4px;
    }
    .km-tips{
      display: flex;
      justify-content: flex-end;
      color: rgba(13, 23, 26, 0.45);
    }
}

</style>